import {Component, OnInit} from '@angular/core';
import { RestService } from './rest.service';
import {Color} from 'ng2-charts';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'PID4CPS Dashboard';
  constructor(public rest: RestService ) { }

  mprFall: any = [];
  updateIntervall = 1;
  change = 0;
  alertclosedTemp = false;
  alertclosedLaut = false;
  alertclosedVibr = false;
  TempSchadensIndex = -1;
  TempToleranzIndex = -1;
  LautToleranzIndex = -1;
  LautSchadensIndex = -1;
  VibrToleranzIndex = -1;
  VibrSchadensIndex = -1;
  zstTemperatur = '';
  zstLautstaerke = '';
  zstVibration = '';
  toleranzstand: any[] = [3];
  schadenstand: any[] = [3];
  alertTemp = 'noAlert';
  alertLaut = 'noAlert';
  alertVibr = 'noAlert';
  datum: string;
  maxValues = 20;
  fullTemp = 'noOverlay';
  fullVibr = 'noOverlay';
  fullRPM = 'noOverlay';
  fullLaut = 'noOverlay';
  fullscreen = 'noOverlay';
  activeFall1 = 'active';
  activeFall2: string;
  activeFall3: string;
  activeFall4: string;
  qualitaet = 'Optimale Qualität';
  status: string;
  warnungTemp = false;
  warnungVibr = false;
  warnungLaut = false;
  maxTempTol = 75;
  maxTempSch = 150;
  maxLautTol = 80;
  maxLautSch = 117;
  maxVibrTol = 0.15;
  maxVibrSch = 0.4;
  maxDiffTemp = 6;
  maxDiffVibr = 0.02;

  chartOptions = {
    responsive: true,
    animation: { duration: 0}
  };
  public lineChartColors: Color[] = [
    { // blue
      backgroundColor: 'rgba(0,65,113,0.05)',
      borderColor: 'rgba(0,65,113,0.6)',
      pointBackgroundColor: 'rgba(0,65,113,0.6)',
      pointBorderColor: 'rgba(0,65,113,0.8)',
      pointHoverBackgroundColor: 'rgba(0,65,113,0.6)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // orange
      backgroundColor: 'rgba(0,65,113,0.05)',
      borderColor: 'rgb(194,138,0)',
      pointBackgroundColor: 'rgb(194,138,0)',
      pointBorderColor: 'rgb(194,138,0)',
      pointHoverBackgroundColor: 'rgb(194,138,0)',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // red
      backgroundColor: 'rgba(0,65,113,0.05)',
      borderColor: 'rgb(141,22,37)',
      pointBackgroundColor: 'rgb(141,22,37)',
      pointBorderColor: 'rgb(141,22,37)',
      pointHoverBackgroundColor: 'rgb(141,22,37)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  chartDataTemp: any[] = [{ data: [] }];
  chartDataLaut: any[] = [{ data: [] }];
  chartDataVibr: any[] = [{ data: [] }];
  chartDataRpm: any[] = [{ data: [] }];

  maxTempTolArray: number[] = [];
  maxTempSchArray: number[] = [];
  maxLautTolArray: number[] = [];
  maxLautSchArray: number[] = [];
  maxVibrTolArray: number[] = [];
  maxVibrSchArray: number[] = [];

  chartLabels: any[] = [{ data: [] }];

  ngOnInit() {
    this.rest.getMprFall1().subscribe((data1: {}) => {
      this.mprFall = data1;
      this.chartInit();
    });
    this.resetZustaende();
    if (window.outerWidth < 500) {
      alert('Zu kleines device! Bitte drehe dein device!');
    }
  }

  async chartInit() {
    let count = 0;
    let start = 0;
    let fall: number;

    this.qualitaet = 'Optimale Qualität';

    if (this.activeFall1 === 'active') {
      fall = 1;
    }
    if (this.activeFall2 === 'active') {
      fall = 2;
    }
    if (this.activeFall3 === 'active') {
      fall = 3;
    }
    if (this.activeFall4 === 'active') {
      fall = 4;
    }
    while (1) {
      if (this.change !== 0) {
        console.log('change quit');
        return;
      }
      if (this.activeFall1 === 'active' && !(fall === 1)) {
        console.log('1 weg');
        return;
      }
      if (this.activeFall2 === 'active' && !(fall === 2)) {
        console.log('2 weg');
        return;
      }
      if (this.activeFall3 === 'active' && !(fall === 3)) {
        console.log('3 weg');
        return;
      }
      if (this.activeFall4 === 'active' && !(fall === 4)) {
        console.log('4 weg');
        return;
      }
      if (count >= this.maxValues && count !== 0) {
        start = count - this.maxValues;
      }
      await this.delay(this.updateIntervall * 1000);
      this.chartDataTemp = [
        { data:  this.getTempArray(this.mprFall, start, count), label: 'Temperatur' },
        { data:  this.maxTempTolArray, label: 'Toleranzgrenze: ' /*+ this.TempToleranzIndex*/ },
        { data:  this.maxTempSchArray, label: 'Schadensgrenze: ' /*+ this.TempSchadensIndex*/ }
      ];
      this.chartDataLaut = [
        { data: this.getLautArray(this.mprFall, start, count), label: 'Lautstärke' },
        { data: this.maxLautTolArray, label: 'Toleranzgrenze: ' /*+ this.maxLautTol*/ },
        { data: this.maxLautSchArray, label: 'Schadensgrenze: ' /*+ this.maxLautSch*/ }
      ];
      this.chartDataVibr = [
        { data: this.getVibrArray(this.mprFall, start, count), label: 'Vibration' },
        { data: this.maxVibrTolArray, label: 'Toleranzgrenze: ' /*+ this.maxVibrTol*/ },
        { data: this.maxVibrSchArray, label: 'Schadensgrenze: ' /*+ this.maxVibrSch*/ }
      ];
      this.chartDataRpm = [
        { data: this.getRpmArray(this.mprFall, start, count), label: 'RPM' },
      ];
      count = +count + +this.updateIntervall;

      if (this.toleranzstand[0] && !(this.alertclosedTemp)) {
        this.zstTemperatur = 'Temperatur: Toleranzgrenze ueberschritten<br>';
        this.warnungTemp = true;
      }
      if (this.toleranzstand[1] && !(this.alertclosedLaut)) {
        this.zstLautstaerke = 'Lautstaerke: Toleranzgrenze ueberschritten<br>';
        this.warnungLaut = true;
      }
      if (this.toleranzstand[2] && !(this.alertclosedVibr)) {
        this.zstVibration = 'Vibration: Toleranzgrenze ueberschritten ';
        this.warnungVibr = true;
      }
      if (this.schadenstand[0]) {
        this.zstTemperatur = 'Temperatur: Schadensgrenze ueberschritten <br>';
        this.warnungTemp = true;
      }
      if (this.schadenstand[1]) {
        this.zstLautstaerke = 'Lautstaerke: Schadensgrenze ueberschritten <br>';
        this.warnungLaut = true;
      }
      if (this.schadenstand[2]) {
        this.zstVibration = 'Vibration: Schadensgrenze ueberschritten';
        this.warnungVibr = true;
      }
      this.status = this.zstTemperatur.concat(this.zstLautstaerke.concat(this.zstVibration));
      if (this.warnungTemp && !this.alertclosedTemp) {
        this.alertTemp = 'alert';
        this.alertclosedTemp = false;
      }
      if (this.warnungLaut && !this.alertclosedLaut) {
        this.alertLaut = 'alert';
        this.alertclosedLaut = false;
      }
      if (this.warnungVibr && !this.alertclosedVibr) {
        this.alertVibr = 'alert';
        this.alertclosedVibr = false;
      }
      this.chartLabels = this.getDatumArray(start, count);
    }
  }

  getDatumArray(start, count)  {
    // console.log('test');
    // console.log(this.mprFall1);
    const array: string[] = [];
    for (const m of this.mprFall) {
      // console.log('test2');
      array.push(m.datum.split(' ')[1]);
    }
    this.datum = this.mprFall[0].datum.split(' ')[0];
    return array.slice(start, count - this.updateIntervall + 1);
  }

  getRpmArray(mprFall: any[], start, count) {
    const array: number[] = [];
    for (const m of mprFall) {
      array.push(m.werte.Rpm);
    }
    return array.slice(start, count);
  }
  getTempArray(mprFall: any[], start, count) {
    const array: number[] = [];
    this.maxTempTolArray = [];
    this.maxTempSchArray = [];
    for (const m of mprFall) {
      const temp: number = m.werte.Tavg_temp.replace(',', '.');
      array.push(temp);
      this.maxTempTolArray.push(this.maxTempTol);
      this.maxTempSchArray.push(this.maxTempSch);
    }
    if ( array[count] > this.maxTempTol && !(array[count - 1] > this.maxTempTol ) ) {
      this.alertToleranz(this.TempToleranzIndex, Art.Temeratur);
      this.alertclosedTemp = false;
    }
    if ( array[count] > this.maxTempSch && !(array[count - 1] > this.maxTempSch ) ) {
      this.alertSchaden(this.TempSchadensIndex , Art.Temeratur);
      this.alertclosedTemp = false;
    }
    let countMinusFive = +count - 5;
    if (countMinusFive < 0) {
      countMinusFive = 0;
    }
    if (Math.abs(array[count] - array[countMinusFive]) > this.maxDiffTemp) {
      this.qualitaet = 'Lose Teile';
    }
    return array.slice(start, count);
  }

  getLautArray(mprFall: any[], start, count) {
    const array: number[] = [];
    this.maxLautTolArray = [];
    this.maxLautSchArray = [];
    for (const m of mprFall) {
      const laut: number = m.werte.Tavg_laut.replace(',', '.');
      array.push(laut);
      this.maxLautTolArray.push(this.maxLautTol);
      this.maxLautSchArray.push(this.maxLautSch);
    }
    if ( array[count] > this.maxLautTol && !(array[count - 1] > this.maxLautTol ) ) {
      this.alertToleranz(this.LautToleranzIndex, Art.Lautstaerke);
      this.alertclosedLaut = false;
    }
    if ( array[count] > this.maxLautSch && !(array[count - 1] > this.maxLautSch ) ) {
      this.alertSchaden(this.LautSchadensIndex , Art.Lautstaerke);
      this.alertclosedLaut = false;
    }
    return array.slice(start, count);
  }

  getVibrArray(mprFall: any[], start, count) {
    const array: number[] = [];
    this.maxVibrTolArray = [];
    this.maxVibrSchArray = [];
    for (const m of mprFall) {
      const vibr: number = m.werte.Tavg_vibr.replace(',', '.');
      array.push(vibr);
      this.maxVibrTolArray.push(this.maxVibrTol);
      this.maxVibrSchArray.push(this.maxVibrSch);
    }

    if ( array[count] > this.maxVibrTol && !(array[count - 1] > this.maxVibrTol ) ) {
      this.alertToleranz(this.VibrToleranzIndex, Art.Vibration);
      this.alertclosedVibr = false;
    }
    if ( array[count] > this.maxVibrSch && !(array[count - 1] > this.maxVibrSch ) ) {
      this.alertSchaden(this.VibrSchadensIndex , Art.Vibration);
      this.alertclosedVibr = false;
    }
    let countMinusFive = +count - 5;
    if (countMinusFive < 0) {
      countMinusFive = 0;
    }
    if (Math.abs(array[count] - array[countMinusFive]) > this.maxDiffVibr) {
      this.qualitaet = 'Lose Teile';
    }
    return array.slice(start, count);
  }

  alertSchaden(wert: number, art: Art) {
    switch (art) {
      case Art.Temeratur:
        this.schadenstand[0] = true;
        this.qualitaet = 'Defekt';
        break;
      case Art.Lautstaerke:
        this.schadenstand[1] = true;
        this.qualitaet = 'Defekt';
        break;
      case Art.Vibration:
        this.schadenstand[2] = true;
        this.qualitaet = 'Defekt';
        break;
    }
  }

  alertToleranz(wert: number, art: Art) {
    switch (art) {
      case Art.Temeratur:
        this.toleranzstand[0] = true;
        this.qualitaet = 'Mindere Qualität';
        break;
      case Art.Lautstaerke:
        this.toleranzstand[1] = true;
        this.qualitaet = 'Mindere Qualität';
        break;
      case Art.Vibration:
        this.toleranzstand[2] = true;
        this.qualitaet = 'Mindere Qualität';
        break;
    }
  }

  async restartCharts() {
    this.change = 1;
    await this.chartInit();
    this.change = 0;
  }

  async onChangeMaxValues(value) {
    console.log(value);
    this.maxValues = value;
    this.restartCharts();
  }

  async onChangeIntervall(value) {
    console.log(value);
    this.updateIntervall = value;
    console.log('update interval: ' + this.updateIntervall);
    this.restartCharts();
    console.log('update interval: ' + this.updateIntervall);
  }

  async onChange(value) {
    this.zstTemperatur = '';
    this.zstLautstaerke = '';
    this.zstVibration = '';
    this.warnungVibr = false;
    this.warnungTemp = false;
    this.warnungLaut = false;
    this.alertLaut = 'noAlert';
    this.alertTemp = 'noAlert';
    this.alertVibr = 'noAlert';
    this.fullTemp = 'noOverlay';
    this.fullVibr = 'noOverlay';
    this.fullRPM = 'noOverlay';
    this.fullLaut = 'noOverlay';
    this.resetZustaende();
    switch (value) {
      case 'Fall1':
        this.rest.getMprFall1().subscribe((data1: {}) => {
          this.mprFall = data1;
          // this.chartInit();
          this.activeFall1 = 'active';
          this.activeFall3 = '';
          this.activeFall2 = '';
          this.activeFall4 = '';
          this.alertclosedTemp = false;
          this.alertclosedLaut = false;
          this.alertclosedVibr = false;
        });
        break;
      case 'Fall2':
        this.rest.getMprFall2().subscribe((data1: {}) => {
          this.mprFall = data1;
          // this.chartInit();
          this.activeFall2 = 'active';
          this.activeFall1 = '';
          this.activeFall3 = '';
          this.activeFall4 = '';
          this.alertclosedTemp = false;
          this.alertclosedLaut = false;
          this.alertclosedVibr = false;
        });
        break;
      case 'Fall3':
        this.rest.getMprFall3().subscribe((data1: {}) => {
          this.mprFall = data1;
          // this.chartInit();
          this.activeFall3 = 'active';
          this.activeFall1 = '';
          this.activeFall2 = '';
          this.activeFall4 = '';
          this.alertclosedTemp = false;
          this.alertclosedLaut = false;
          this.alertclosedVibr = false;
        });
        break;
      case 'Fall4':
        this.rest.getMprFall4().subscribe((data1: {}) => {
          this.mprFall = data1;
          // this.chartInit();
          this.activeFall4 = 'active';
          this.activeFall1 = '';
          this.activeFall2 = '';
          this.activeFall3 = '';
          this.alertclosedTemp = false;
          this.alertclosedLaut = false;
          this.alertclosedVibr = false;
        });
        break;
    }
    await (this.chartInit());
    this.chartInit();
  }

  resetZustaende() {
    this.toleranzstand[0] = false;
    this.toleranzstand[1] = false;
    this.toleranzstand[2] = false;
    this.schadenstand[0] = false;
    this.schadenstand[1] = false;
    this.schadenstand[2] = false;
  }

  closeAlert(graph: string) {
    if ( graph === 'temp') {
      this.alertTemp = 'noAlert';
      this.alertclosedTemp = true;
    }
    if ( graph === 'laut') {
      this.alertLaut = 'noAlert';
      this.alertclosedLaut = true;
    }
    if ( graph === 'vibr') {
      this.alertVibr = 'noAlert';
      this.alertclosedVibr = true;
    }
  }

  showFullscreen( graph: string) {
    this.fullscreen = 'overlay';
    if (graph === 'temp') {
      this.fullTemp = 'overlayTemp';
      this.fullVibr = 'noOverlay';
      this.fullRPM = 'noOverlay';
      this.fullLaut = 'noOverlay';
    }
    if (graph === 'laut') {
      this.fullLaut = 'overlayLaut';
      this.fullVibr = 'noOverlay';
      this.fullRPM = 'noOverlay';
      this.fullTemp = 'noOverlay';
    }
    if ( graph === 'vibr') {
      this.fullVibr = 'overlayVib';
      this.fullTemp = 'noOverlay';
      this.fullRPM = 'noOverlay';
      this.fullLaut = 'noOverlay';
    }
    if ( graph === 'RPM') {
      this.fullRPM = 'overlayRPM';
      this.fullLaut = 'noOverlay';
      this.fullVibr = 'noOverlay';
      this.fullTemp = 'noOverlay';
    }
  }
  closeFullscreen() {
    this.fullscreen = 'noOverlay';
  }
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}

enum Art {
  Temeratur, Lautstaerke, Vibration
}
